<template>
  <div class="donepage">
    <div class="icon"><van-icon name="checked" /></div>
    <div class="text">提交成功</div>
    <div class="btn">
      <button type="button" v-if="fromstatus == 0" @click="applyEuipment">
        继续申请设备
      </button>
      <button type="button" v-else @click="backHome">返回首页</button>
    </div>

    <div class="backhome" v-if="fromstatus == 0" @click="backHome">返回首页</div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import mitts from "@/bus";

const route = useRoute();
const router = useRouter();

const fromstatus = ref(null);

function applyEuipment() {
  router.push({
    path: "/StoreManagement/ApplyEquipment",
    query: {
      apply_id: route.query.apply_id,
      source:"store",
      storeName: route.query.storeName
    },
  });
}

function backHome() {
  router.push({
    path: "/event/event",
    query: {url:encodeURIComponent('/web/applyList')}
  });
}

onMounted(() => {
  fromstatus.value = route.query.fromstatus;
  mitts.emit("closeLoading");
});
</script>

<style lang="less" scoped>
.donepage {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-flow: column;
  padding: 80px 20px 0 20px;
  .icon {
    font-size: 200px;
    line-height: 200px;
    color: #68bf7b;
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
  }
  .text {
    font-size: 36px;
    line-height: 36px;
    color: #333;
    margin-bottom: 50px;
  }
  .btn {
    width: 100%;
    margin-bottom: 20px;
    button {
      font-size: 28px;
      height: 80px;
      line-height: 80px;
      border-radius: 40px;
      border: 3px solid @theme-secondary-color;
      background: @theme-secondary-color;
      color: @font-white-color;
      width: 100%;
    }
  }
  .backhome {
    text-decoration: underline;
  }
}
</style>
